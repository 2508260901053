<template>
    <b-card
      no-body
    >
      <b-card-body>
        <b-card-title>{{$t('Payments')}}</b-card-title>
      </b-card-body>
      <b-table
        striped
        responsive
        :items="payments"
        class="mb-0"
        style="max-height: 40vh;"
        sticky-header="true"
        :no-border-collapse="true"
        :fields="translatedTableColumns"
        show-empty
        :empty-text="$t('table.empty')"
      >
        <template #cell(details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? $t('table.hide') : $t('table.show') }}
          </b-form-checkbox>
        </template>
  
        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>ID : </strong>{{ row.item.id }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>{{$t('Payments')}} ID : </strong>{{ row.item.payment_id }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-if="row.item.payment_method_id == 'Stripe'"
              >
                <strong>Stripe ID : </strong>{{ userData.stripe_id }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-else
              >
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>{{$t('fields.created_at')}} : </strong>{{ new Date(row.item.created_at).toLocaleDateString() }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>{{$t('fields.updated_at')}} : </strong>{{ new Date(row.item.updated_at).toLocaleDateString() }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>{{$t('fields.deleted_at')}} : </strong>{{ row.item.deleted_at ? new Date(row.item.deleted_at).toLocaleDateString() : '' }}
              </b-col>
            </b-row>
  
          </b-card>
        </template>
  
        <template #cell(payment_method_id)="data">
          <b-link
            :to="{ name: 'partners-view', params: {id: data.item.payment_method_id} }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.payment_method_id }} 
          </b-link>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="itemIsActiveColor(data.item)">
            {{ $t(itemIsActive(data.item)) }}
          </b-badge>
        </template>
        <template #cell(valid_from)="data">
            {{ data.item.valid_from ? new Date(data.item.valid_from).toLocaleDateString() :''}} 
          </template>
        <template #cell(valid_until)="data">
            {{ data.item.valid_until ?new Date(data.item.valid_until).toLocaleDateString() :''}}
        </template>
        <template v-if="show_edit_column" #cell(actions)="data">
          <b-button
            v-if="canBeEdited(data.item.payment_method_id)"
            variant="outline-secondary"
            type="button"
            @click="setPayment(data.item.id)"
          >
            {{$t('forms.edit')}}
          </b-button>
        </template>
      </b-table>
    </b-card>
  </template>
  
  <script>
  import {
    BBadge,  
    BButton, 
    BCard, 
    BCardBody, 
    BCardSubTitle, 
    BCardTitle, 
    BCol, 
    BFormCheckbox,
    BLink,
    BRow,
    BTable,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BBadge,  
      BButton, 
      BCard, 
      BCardBody, 
      BCardSubTitle, 
      BCardTitle, 
      BCol,
      BFormCheckbox,
      BLink,
      BRow,
      BTable,
    },
    props: {
      show_edit_column: {
        type: Boolean,
        required: false,
      },
      payments: {
        type: Array,
        required: true,
      },
      blocked_payment_methods: {
        type: Array,
        required: false
      },
      userData: {
        type: Object,
        required: true
      }
    },
    data(){
      let tableColumns = [
          { key: 'details', sortable: false, label: this.$t('Details') },
          { key: 'payment_method_id', sortable: true, label: this.$t('fields.payment_method_id') },
          { key: 'status', label: this.$t('fields.status') },
          { key: 'valid_from', sortable: true, label: this.$t('fields.valid_from') },
          { key: 'valid_until', sortable: true, label: this.$t('fields.valid_until') },
      ]
      if(this.show_edit_column){
        tableColumns.push({key: 'actions', label: this.$t('fields.actions')})
      }
      return {
        tableColumns,
        translatedTableColumns: tableColumns.map(column => ({
          ...column,
          label: column.label
        }))
      }
    },
    methods: {
      itemIsActive(item){
        if(item.deleted_at !== null) return "fields.deleted"
        if(item.valid_until === null) return "fields.in_use"
        if(new Date(item.valid_until) > new Date()) return "fields.in_use"
        else return "fields.expired"
      },
      itemIsActiveColor(item){
        if(item.deleted_at !== null) return "light-danger"
        if(item.valid_until === null) return "light-success"
        if(new Date(item.valid_until) > new Date()) return "light-success"
        else return "light-danger"
      },
      canBeEdited(payment_method_id){
        if(this.blocked_payment_methods){
          return !this.blocked_payment_methods.includes(payment_method_id)
        }
        return false
      },
      setPayment(payment_id){
        this.$emit('set-payment', payment_id);
      }
    },
  }
  </script>
  
  <style>
  
  </style>
